<template>
  <div class="product-details mb-10">
    <!-- category -->
    <div class="max-width " v-if="product.title">
      <!-- slider -->
      <v-row>
        <v-col cols="12" md="6">
          <div
            class="
              white
              radius-15
              dark-shadow
              pt-8
              pb-13
              product-details__slider
            "
          >
            <slick-carousel class="circle-dots" v-bind="settings">
              <div class="product-details__slider__slide mx-auto mb-5">
                <img
                  class="object-contain mx-auto"
                  :src="product.image"
                  alt=""
                />
              </div>
            </slick-carousel>
          </div>
        </v-col>

        <v-col cols="12" md="6">
          <v-row align="center" class="pt-0">
            <v-col cols="12">
              <!-- title -->
              <div class="text-center my-4">
                <h2 class="font-35 font-500 primary--text d-block">
                  {{ product.title }}
                </h2>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="d-flex align-center">
                <img
                  width="33"
                  height="33"
                  :src="require('@/assets/images/icons/weight.png')"
                  alt=""
                  class="mx-2"
                />
                <span class="font-30 d-block black--text font-500"
                  >{{ product.weight + $t("kg") }}
                </span>
              </div>
            </v-col>

            <v-col cols="4">
              <div class="d-flex align-center">
                <img
                  width="23"
                  height="23"
                  :src="require('@/assets/images/icons/prise.png')"
                  alt=""
                  class="mx-2"
                />
                <span class="font-30 d-block black--text font-500"
                  >{{ product.price }}$
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row align="center" class="pt-0">
            <!-- <v-col cols="10" class="py-0 mt-1 mb-2">
                <div class="py-2 c-btn white url_v">
                  <a
                    :href="product.url"
                    target="_blank"
                    class="d-flex align-item-center justify-center"
                  >
                    <img
                      width="20"
                      height="20"
                      class="mx-2"
                      :src="require('@/assets/images/icons/link.png')"
                      alt=""
                    />
                    <span class="font-18 primary--text">{{
                      $t("visitLink")
                    }}</span>
                  </a>
                </div>
              </v-col> -->

            <!-- <v-col cols="4">
              <div class="d-flex align-center">
                <img
                  width="27"
                  height="27"
                  class="mx-2"
                  :src="require('@/assets/images/icons/category.png')"
                  alt=""
                />
                <span class="font-20 d-block black--text font-500"
                  >{{ category }}
                </span>
              </div>
            </v-col> -->
          </v-row>

          <!-- wieght -->
          <v-row align="center" class="">
            <v-col cols="12" class="">
              <span class="font-30 d-block black--text font-500">
                <img
                  width="22"
                  height="22"
                  class="mx-3"
                  :src="require('@/assets/images/icons/notes.png')"
                  alt=""
                />
                {{ $t("note") }}</span
              >
            </v-col>
            <v-col cols="12" class="py-0 mb-2">
              <div class="py-1 c-btn white">
                <span class="font-18 lightGreyText--text">{{
                  readMore
                    ? product.description
                    : product.description.slice(0, 200)
                }}</span
                ><span
                  v-if="!readMore && product.description.length > 200"
                  @click="readMore = true"
                  style="color: #89bbcb; font-size: 12px; cursor: pointer"
                >
                  {{ $t("readMore") }}
                </span>
              </div>
            </v-col>
          </v-row>
          <!-- desc -->
        </v-col>
        <v-col class="justify-center" align="center" cols="12" >
          <v-btn width="300px" color="primary" @click="openModalValed"><span>{{$t("buyNow")}}</span></v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="openModal" max-width="1000" content-class="white">
      <buy-now :product="product"  @close="closeModal"></buy-now>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BuyNow from '../../../components/dialogs/BuyNow.vue';
export default {
  components: { BuyNow },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    readMore: false,
    openModal:false,
    settings: {
      arrows: false,
      dots: true,
      focusOnSelect: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  }),

  computed: {
    ...mapGetters(["categories"]),
    // category() {
    //   return this.categories.filter(
    //     (cat) => cat.id == this.product.category_id
    //   )[0].name;
    // },

    
  },
  methods:{
    closeModal(){
      this.openModal=false
    },
    openModalValed(){
      if(localStorage.getItem("role")=="guest"){
        this.$router.push({name:"sign-in"})
      }else{
        this.openModal=true;
      }
    }
  }
};
</script>

<style lang="scss">
.product-details {
  &__slider {
    max-width: 400px;
    margin: auto;
    &__slide {
      img {
        width: 250px !important;
      }
    }
  }
}
</style>
