<template>
  <div class="product-details">
    <!-- head section -->
    <div v-if="loading" style="width: 100%; height: 400px; display: flex; justify-content: center; align-items: center;">
      <v-progress-circular :size="40" :width="3" color="primary" indeterminate>
      </v-progress-circular>
    </div>
    <div v-else class="max-width mt-14">
      <h2 class="primary--text">{{ $t("productDetails") }}</h2>
      <Details
        
        :is.sync="selectedSection"
        class="mt-7"
        :product.sync="product"
      ></Details>
      <!-- <div class="custom-tabs__tabs d-flex align-center justify-space-start">
        <div
          class="ustom-tabs__tabs__tab text-center cursor-pointer mx-2 px-5"
          :class="tab.active ? 'custom-tabs__tabs__tab--active' : ''"
          v-for="(tab, i) in tabs"
          :key="i"
          @click="tabClicked(tab)"
        >
          <span
            class="font-25"
            :class="tab.active ? 'halfwhite--text' : 'grey--text'"
            >{{ tab.title }}</span
          >
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <component
          :is="selectedSection"
          class="mt-7"
          :product="product"
        ></component>
      </transition> -->
    </div>
  </div>
</template>

<script>
// import SectionHead from "../../components/core/SectionHead.vue";
import Details from "./components/Details.vue";

export default {
  components: {
    //  SectionHead,
    Details,
  },
  data: (vm) => ({
    loading: false,
    tabs: [
      {
        id: 1,
        title: vm.$t("productDetails"),
        active: false,
        component: () => import("./components/Details"),
      },
      {
        id: 2,
        title: vm.$t("productDetails"),
        active: false,
        component: () => import("./components/BuyNow"),
      },
    ],
    product: {},
    selectedSection: () => import("./components/Details"),
  }),
  watch: {
    $route: {
      handler() {
        this.checkRoute();
      },
    },
  },
  methods: {
    tabClicked(tab) {
      this.selectedSection = tab.component;
      this.tabs.forEach((t) => {
        if (t.id == tab.id) {
          t.active = true;
        } else {
          t.active = false;
        }
      });
    },
    async getData() {
      this.loading = true;
      let { data } = await this.$axios.get("/product/" + this.$route.params.id);
      if (data.data) {
        this.loading = false;
        this.product = data.data.product;
      }
    },
    checkRoute() {
      if (this.$route.name == "Product Order") {
        this.tabs[1].active = true;
        this.tabs[0].active = true;
        this.selectedSection = this.tabs[0].component;
      }
    },
  },
  async created() {
    // this.checkRoute();
    await this.getData();
  },
};
</script>

<style lang="scss"></style>
