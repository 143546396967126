<template>
  <div class="buy-dialog pt-10">
    <div class="px-10 text-center">
      <v-row>
        <v-col cols="12" md="6">
          <base-date
            :placeholder="$t('shipmentDate')"
            v-model="shipment_date"
            @handleDate="shipment_date = $event"
          ></base-date>
        </v-col>

        <v-col cols="12" md="6">
          <base-select
            :placeholder="$t('category')"
            :items="categories"
            v-model="category_id"
          ></base-select>
        </v-col>

        <v-col cols="12" md="9">
          <google-map
            @click="addLocation($event)"
            :location="destinationLocation"
            :markers="markers"
          ></google-map>
        </v-col>
        <v-col cols="12" md="3">
          <v-row>
            <v-col cols="12" class="mt-0 pt-0">
              <span class="font-25 primary--text d-block mb-3">{{
                $t("destinationAddress")
              }}</span>
              <vue-google-autocomplete
                
                ref="address"
                id="map"
                outlined
                classname="form-control place-input mb-2"
                types="locality"
                v-on:placechanged="getAddressData($event)"
                :placeholder="$t('goToTheCity')"
              >
              </vue-google-autocomplete>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0">
              <view-field
                :key="keyUpdated"
                :value="country"
                :title="$t('country')"
              ></view-field>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0">
              <view-field
                :key="keyUpdated"
                :value="city"
                :title="$t('city')"
              ></view-field>
            </v-col>
            <v-col cols="12">
              <view-field
                :key="keyUpdated"
                :value="address"
                :title="$t('address')"
              ></view-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="my-5">
        <v-btn
          class="c-btn mx-1"
          color="grey"
          height="40"
          @click="$emit('close')"
          ><span class="white--text font-300 font-20 d-inline-block px-7">{{
            $t("no")
          }}</span>
        </v-btn>
        <v-btn class="c-btn mx-1" color="primary" height="40" @click="buyNow"
          ><span class="white--text font-300 font-20 d-inline-block px-7">{{
            $t("send")
          }}</span
          ><span>
            <v-progress-circular
              v-if="loading"
              :size="15"
              color="lightGreyText"
              indeterminate
            ></v-progress-circular>
          </span>
        </v-btn>
      </div>
      {{addressDestination+"console"}}
    </div>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import GoogleMap from "../base/GoogleMap.vue";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
import axios from "axios"
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    GoogleMap
  },
  data: () => ({
    account: "",
    step: 1,
    category_id: "",
    shipment_date: "",
    address: "",
    country: "",
    city: "",
    markers: [{ lng: null, lat: null }],
    destinationLocation: { lat: 33.5138, lng: 36.2765 },
    latitude_destination: "",
    longitude_destination: "",
    addressDestination: "",
    loading: false,
    keyUpdated: false,
  }),
  computed: {
    ...mapGetters(["categories", "weightItems"]),
  },
  methods: {
    btnClicked() {
      if (this.step == 1) {
        this.step = 2;
      } else {
        this.$emit("ok");
      }
    },
    async addLocation(event) {
      let response = await this.$store.dispatch("setMapMarker", event);
      let lng = event.latLng.lng();
      let lat = event.latLng.lat();

      if (response.country) {
        this.address = response.fullAddress;
        this.country = response.country;
        this.city = response.city;
        this.keyUpdated = !this.keyUpdated;
        this.markers[0].lng = lng;
        this.markers[0].lat = lat;
        this.latitude_destination = lat;
        this.longitude_destination = lng;
      } else {
        this.address = "";
        this.country = "";
        this.city = "";
        this.$store.dispatch("showSnack", {
          text: "Not Available Address",
          color: "error",
          timeout: 2000,
        });
        this.keyUpdated = !this.keyUpdated;
      }
    },
    getAddressData(addressData) {
      if (addressData) {
        this.destinationLocation.lat = addressData.latitude;
        this.destinationLocation.lng = addressData.longitude;
      }
    },
    buyNow() {
      this.loading = true;
      this.$axios
        .post("/buyForMe", {
          shipment_date: this.shipment_date,
          product_id: this.product.id,
          country: this.country,
          city: this.city,
          address: this.address,
          latitude: this.latitude_destination,
          longitude: this.longitude_destination,
          category_id: this.category_id,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  watch:{
    shipment_date(val){
      console.log(val,"console")
      axios.get("https://maps.googleapis.com/maps/api/mapsjs/gen_204",{params:{csp_test:true}})
    }
  },
  created(){
    console.log(this.shipment_date)
  }
};
</script>
  
  <style lang="scss">
</style>
  